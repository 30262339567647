<mat-toolbar color="primary" class="flex justify-between items-center">
    <div class="flex">
        <a mat-button [routerLink]="['/']" >Home</a>
    </div>

    <div class="flex">
        <button *ngIf="isLoggedIn && user" mat-button [matMenuTriggerFor]="me">
            <span>Hi, {{user.username}}</span>
            <span *ngIf="selectedOrganization">. You're in {{selectedOrganization}}</span>
            <span *ngIf="!selectedOrganization">. *SELECT ORG*</span>
            <mat-icon>expand_more</mat-icon>
        </button>

        <button (click)="login()" *ngIf="!isLoggedIn" mat-button >
            {{isLoggingIn ? 'Logging in...' : 'Login'}}
            <mat-progress-bar *ngIf="isLoggingIn" color="accent" mode="buffer" ></mat-progress-bar>
        </button>

        <mat-menu #orgsMenu="matMenu">
            <ng-template matMenuContent>
                <button *ngFor="let org of organizations" mat-menu-item (click)="selectOrganization(org)">{{org}}</button>
            </ng-template>
        </mat-menu>

        <mat-menu #adminMenu="matMenu" >
            <a mat-menu-item routerLink="/manage/menu-items" >Manage Menu Items</a>
            <a mat-menu-item routerLink="/manage/users" >Manage Users</a>
            <a mat-menu-item routerLink="/manage/data-versions" >Manage Data Versions</a>
        </mat-menu>

        <mat-menu #me="matMenu">
            <a mat-menu-item routerLink="/mystore">My Store</a>
            <button *ngIf="isLoggedIn && (hasPermission('ManageUsers') || user.role === 'SuperUser')" mat-menu-item [matMenuTriggerFor]="adminMenu" >Admin Menu</button>
            <button mat-menu-item [matMenuTriggerFor]="orgsMenu">Select Organization</button>
            <button mat-menu-item (click)="logout()" *ngIf="isLoggedIn" >Logout</button>
        </mat-menu>
    </div>
</mat-toolbar>
