import { PromptService } from './../_shared/services/prompt.service';
import { AuthService } from './../_shared/services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({providedIn: 'root'})
export class SuperUserGuard  {
    constructor(private _auth: AuthService, private _prompt: PromptService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isSuperUser = this._auth.user?.role === 'SuperUser';
        if(!isSuperUser) this._prompt.error('Insufficient authority');
        return isSuperUser;
    }
}
