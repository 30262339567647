import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';

import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { APP_ROUTES } from './app/app-routing.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

if (environment.production) {
  enableProdMode();
}

function getToken() {
  //has export in front
  return localStorage.getItem('backend-client-token');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      JwtModule.forRoot({
        config: {
            tokenGetter: getToken,
            allowedDomains: ['localhost:10000'],
            disallowedRoutes: ['http://localhost:10000/api/auth']
        }
    })
    ),
    provideRouter(APP_ROUTES),
    provideAnimations(),
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    provideHttpClient(withInterceptorsFromDi()),
  ]
}).catch(err => console.error(err));