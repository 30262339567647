import { SuperUserGuard } from './_guards/super-user.guard';
import { HasSelectedOrganizationGuard } from './_guards/has-selected-organization.guard';
import { AuthGuard } from './_guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { Routes } from '@angular/router';
import {CanManageUsersGuard} from "./_guards/can-manage-users.guard";

export const APP_ROUTES: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'manage/menu-items',
        loadChildren: () => import('./menu-items-manager/menu-items-manager-routing.module'),
        canActivate: [AuthGuard, SuperUserGuard]
    },
    {
        path: 'manage/users',
        loadChildren: () => import('./users/users-routing.module'),
        canActivate: [AuthGuard]
    },
    {
        path: 'mystore',
        loadChildren: () => import('./my-store/my-store-routing.module'),
        canActivate: [AuthGuard, HasSelectedOrganizationGuard]
    },
    {
        path: 'manage/data-versions',
        loadChildren: () => import('./data-versions/data-versions-routing.module'),
        canActivate: [AuthGuard, CanManageUsersGuard]
    },
];
