import { Router, RouterModule } from '@angular/router';
import { UserDto } from './../_dtos/user-dtos/user-dto';
import { PromptService } from './../_shared/services/prompt.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../_shared/services/auth.service';
import { LoginDto } from '../_dtos/auth-dtos/login-dto';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [MatToolbarModule, RouterModule, MatMenuModule, MatButtonModule, MatIconModule, CommonModule]
})
export class HeaderComponent implements OnInit, OnDestroy {
    private _userSub: Subscription;
    user: UserDto;

    isLoggedIn = false;
    isLoggingIn = false;

    organizations: string[];
    selectedOrganization: string;

    constructor(private _auth: AuthService,
        private _router: Router,
        private _prompt: PromptService) {}

    ngOnInit(): void {
        this.isLoggedIn = this._auth.user != null;
        this.selectedOrganization = this._auth.selectedOrganization?.name;

        this._userSub = this._auth.userSub
        .subscribe(user => {
            this._setUserDetails(user);
            this.isLoggingIn = false;
        }, error => {
            this._prompt.error(error, null, true);
            this.isLoggingIn = false;
        });

        this._setUserDetails(this._auth.user);
    }

    ngOnDestroy() {
        this._userSub.unsubscribe();
    }

    login() {
        if(this.isLoggingIn) return;

        const ref = this._prompt.getUserCredentials('Login');

        ref.afterClosed().subscribe(data => {
            if(!data) return;
            this.isLoggingIn = true;

            const dto: LoginDto = {
                username: data.username,
                password: data.password
            };

            this._auth.login(dto);
        });
    }

    logout(){
        this._auth.logout();
        this.selectedOrganization = null;
    }

    selectOrganization(orgName: string) {
        const organization = this.user.organizations.find(x => x.name === orgName);
        if(organization == null) {
            this._router.navigate(['/']);
            return;
        }

        this._auth.selectOrganization(organization);
        this.selectedOrganization = organization?.name;
        this._auth.selectedOrganization = organization;
    }

    hasPermission(permission: string) {
        if(this.user == null) return false;
        return this.user.permissions.some(x => x.permission === permission);
    }

    private _setUserDetails(user: UserDto) {
        this.isLoggedIn = user != null;

        if(this.user != null) return;
        if(user == null) return;

        this.user = user;
        this.organizations = user?.organizations.filter(x => x.name !== 'Commissary').map(x => x.name);
    }
}
