import { Component } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [HomeComponent, HeaderComponent, MatMomentDateModule, RouterOutlet]
})
export class AppComponent {
  title = 'backend-client';
}
